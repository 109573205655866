import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import "./App.css";
import { Box, Button, TextField, Typography } from "@mui/material";

function App() {
  const [Data, setData] = useState([]);
  const [selectOption, setSelectOption] = useState("car");
  const [open, setOpen] = useState(false);
  const fetchSubmittedData = () => {
    fetch("https://ipotank.com/contactapi/getData", {
      headers: { Authorization: selectOption },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    fetchSubmittedData();
  }, [selectOption]);

  const handleDelete = (id) => {
    fetch(`https://ipotank.com/contactapi/deleteData/${id}`, {
      method: "DELETE",
      headers: { Authorization: selectOption },
    })
      .then((response) => {
        if (response.ok) {
          window.alert("Data deleted successfully!");
          setData((prevData) => prevData.filter((data) => data.id !== id));
        } else {
          window.alert("Failed to delete data. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        window.alert("Failed to delete data. Please try again.");
      });
  };

  const [answer, setAnswer] = useState("");

  const handleInputChange = (event) => {
    setAnswer(event.target.value);
  };
  const handleSubmit = () => {
    if (answer === "Anjali Patel") {
      setOpen(true);
      setAnswer("");
    } else {
      alert("Enter correct answer");
    }
  };
  return (
    <>
      {open ? (
        <div>
          {/* <div> */}
          <div
            className="navbar"
            style={{ fontSize: "30px", fontStyle: "larger" }}
          >
            Contact Information
          </div>

          <div className="input-container">
            <button
              className="button"
              onClick={() => setSelectOption("car")}
              style={{ marginRight: "15px" }}
            >
              Car Contact
            </button>

            <button
              onClick={() => setSelectOption("bike")}
              style={{ marginRight: "15px" }}
            >
              Bike Contact
            </button>
            <button onClick={() => setSelectOption("ipo")}>IPO Contact</button>
          </div>
          {/* </div> */}

          <div className="table-container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                marginRight: "20px",
              }}
            >
              <h3 style={{ textAlign: "left" }}>
                {selectOption === "car"
                  ? "Car"
                  : selectOption === "bike"
                  ? "Bike"
                  : "IPO"}{" "}
                Contact Data
              </h3>

              <div style={{ textAlign: "right" }}>
                Total Records: {Data.length}
              </div>
            </div>
            {Data.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Message</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((data, index) => (
                    <tr key={index}>
                      <td width={150}>
                        <Tooltip key={index} title={data.name} arrow>
                          {data.name}
                        </Tooltip>
                      </td>
                      <td width={200}>
                        <Tooltip key={index} title={data.email} arrow>
                          {data.email}
                        </Tooltip>
                      </td>
                      <td width={150}>{data.phone}</td>
                      <td className="large-column">
                        <Tooltip key={index} title={data.message} arrow>
                          {data.message}
                        </Tooltip>
                      </td>
                      <td width={20}>
                        <button
                          onClick={() => handleDelete(data.id)}
                          style={{ backgroundColor: "#D98880", color: "white" }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div style={{ paddingBottom: "10px" }}>Data Not Available</div>
            )}
          </div>
        </div>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          bgcolor="#f5f5f5"
        >
          <Box
            bgcolor="white"
            boxShadow={3}
            borderRadius={2}
            padding="20px"
            height="300px"
            width="600px" // Set fixed width
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h6"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              Who created this app?
            </Typography>

            <TextField
              id="question"
              name="question"
              label="Fill Answer"
              multiline
              variant="outlined"
              value={answer}
              onChange={handleInputChange}
              style={{
                marginBottom: "20px",
                marginTop: "15px",
                width: "400px",
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ width: "200px" }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

export default App;
